import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedDataService } from 'src/app/core/common/sharedDataService';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/core/services/languageService/language.service';
import { LanguageAll } from '../../modules/language-interface/language-item-all'
import { Constants } from 'src/app/core/constants/constant';
import { AuthServiceService } from 'src/app/core/services/auth/auth-service.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  logoTiTle2: String = Constants.LOGO_IMG_2.toString();
  titleName: string = Constants.TITLE_LOGO_1.toString();
  telData: any;
  emailData: any;
  addressData: any;
  contactData: any = [];
  facebook: any;
  instagram: any;
  line: any;
  langAll: any;
  visitor: any;

  constructor(private router: Router,
    private sharedDataService: SharedDataService,
    public translate: TranslateService,
    private authService: AuthServiceService,
    public languageService: LanguageService) {
    this.translate.use(localStorage.getItem("lang"));
  }
  ngOnInit(): void {

    this.langAll = LanguageAll[0];
    this.sharedDataService.languageValue.subscribe((message) => {
      if (message != "")
        this.translate.use(message);
    });
    this.loadContactData();
    this.getVisitor();
  }

  loadContactData() {
    var temp: any = [];
    var temp2: any = [];
    var temp3: any = [];
    var temp4: any = [];
    var temp5: any = [];
    var temp6: any = [];
    this.sharedDataService.getDataContact.subscribe((message) => {
      // console.log('asasas', message);
      if (message.status == true) {
        this.contactData = message.data;
        for (let index = 0; index < this.contactData.length; index++) {
          if (this.contactData[index].contactStatus == true || this.contactData[index].contactStatus == null) {
            if (this.contactData[index].phoneNumber != '' && this.contactData[index].phoneNumber != null && this.contactData[index].contactStatus != null) {
              temp.push(this.contactData[index].phoneNumber);
            } else if (this.contactData[index].email != '' && this.contactData[index].email != null) {
              temp2.push(this.contactData[index].email);
            } else if (this.contactData[index].address != '' && this.contactData[index].address != null) {
              temp3.push(this.contactData[index].address);
            } else if (this.contactData[index].facebook != '' && this.contactData[index].facebook != null) {
              temp4.push(this.contactData[index].facebook);
            } else if (this.contactData[index].anotherChannel != '' && this.contactData[index].anotherChannel != null) {
              temp5.push(this.contactData[index].anotherChannel);
            } else if (this.contactData[index].line != '' && this.contactData[index].line != null) {
              temp6.push(this.contactData[index].line);
            }
          }
        }
        this.telData = temp;
        this.emailData = temp2[0];
        var test = temp3[0].split(',');
        // console.log("address", this.telData);

        this.addressData = test[4] + " " + test[2] + " " + test[1] + " " + test[0] + " " + test[3];
        this.facebook = temp4;
        this.instagram = temp5;
        this.line = temp6;
        // console.log('facebook', temp4);
        // console.log('instagram', temp5);
        // console.log('line', temp6);
      }

    })
  }

  onClickReportproblem() {
    this.router.navigate(['/report-problem']);
  }

  getVisitor() {
    this.authService.GetVisitor().then(resVisitor => {
      // console.log(resVisitor);
      if (resVisitor.status) {
        if (resVisitor != undefined) {
          this.visitor = resVisitor.count;
        }
      }
    })
  }
}
