export class Constants {

    // #region Path API

    // public static REST_API_MAIN: string = 'http://localhost:11997'; //local
    // public static REST_API_MAIN: string = 'http://localhost:5000'; //local

    public static REST_API_MAIN: string = 'https://kpi-api.achatsocial.com'; // UAT test
    public static REST_API_ADDRESS: string = 'https://api.medilifewellness.com:1243';  // UAT test

    // public static REST_API_ADDRESS: string = 'https://api.medilifewellness.com:1243';  // UAT customer
    public static REST_API_SW: string = 'http://releep.achatsocial.com:1243';  // UAT customer

    // public static REST_API_MAIN: string = 'https://lms-connect.achatsocial.com';  // prod
    // public static REST_API_SW: string = 'https://releep.achatsocial.com:1243';    // prod
    // #endregion api

    // #region Img
    public static MAIN_PATH_IMG: string = 'assets/img';

    // #region for EP

    // public static LOGO_IMG_1: string = `${this.MAIN_PATH_IMG}/logo.png`;
    // public static LOGO_IMG_2: string = `${this.MAIN_PATH_IMG}/logoachat.png`;
    // public static LOGO_IMG_3: string = `${this.MAIN_PATH_IMG}/logo-dark.png`;
    // public static LOGO_IMG_4: string = `${this.MAIN_PATH_IMG}/logo-achat.png`;

    // #endregion

    // #region for sanchai
    public static LOGO_IMG_1: string = `${this.MAIN_PATH_IMG}/kpi_logo_sm_cut.png`;
    public static LOGO_IMG_2: string = `${this.MAIN_PATH_IMG}/kpi_logo_sm_cut.png`;
    public static LOGO_IMG_3: string = `${this.MAIN_PATH_IMG}/kpi_logo_sm_cut.png`;
    public static LOGO_IMG_4: string = `${this.MAIN_PATH_IMG}/kpi_logo_sm_cut.png`;
    // #endregion

    //#endregion img

    // #region txt
    // public static TITLE_LOGO_1: string = '';
    public static TITLE_LOGO_1: string = 'การบริหารงานพัฒนาท้องถิ่นที่ยั่งยืน (รุ่นที่6)';
    // public static TITLE_LOGO_1: string = 'สถาบันการแพทย์ฉุกเฉินแห่งชาติ';
    //#endregion txt
}
